import "./Companies.css";

const Companies = () => {
  return (
    <section className="c-wrapper">
      <div className="paddings innerWidth flexCenter c-container">
        <img src='../img/prologis.png' alt="" />
        <img src='../img/tower.png' alt="" />
        <img src='../img/equinix.png' alt="" />
        <img src='../img/realty.png' alt="" />
      </div>
    </section>
  );
};

export default Companies;
