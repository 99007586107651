import "./Contact.css";
import {MdCall} from 'react-icons/md';
import {BsFillChatDotsFill} from 'react-icons/bs';
import {HiChatBubbleBottomCenterText} from 'react-icons/hi2';

const Contact = () => {
  return (
    <section className="c-wrapper">
      <div className="paddings innerWidth flexCenter c-container">
        {/* Left Side */}
        <div className="c-left flexColStart">
          <span className="orangeText">Our Contacts</span>
          <span className="primaryText">Easy to Contact us</span>
          <span className="secondaryText">We always ready to help by providing the best services
            beleive a good place to live can make your life better
          </span>
          <div className="flexColStart contactModes">
            {/* first row */}
            <div className="row">
                <div className="flexStart mode">
                    <div className="flexStart">
                        <div className="flexCenter icon">
                            <MdCall size={25}/>
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Call</span><span>021 123 145 14</span>
                        </div>
                    </div>
                    <div className="flexCenter button">Call Now</div>
                </div>
                <div className="flexColCenter mode">
                    <div className="flexStart">
                        <div className="flexCenter icon">
                            <BsFillChatDotsFill size={25}/>
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Video Call</span>
                            <span>021 123 145 14</span>
                        </div>
                    </div>
                    <div className="flexCenter button">Video Call Now</div>
                </div>
                <div className="flexColCenter mode">
                    <div className="flexStart">
                        <div className="flexCenter icon">
                            <BsFillChatDotsFill size={25}/>
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Chat</span>
                            <span>021 123 145 14</span>
                        </div>
                    </div>
                    <div className="flexCenter button">Chat Now</div>
                </div>
                <div className="flexColCenter mode">
                    <div className="flexStart">
                        <div className="flexCenter icon">
                            <HiChatBubbleBottomCenterText size={25}/>
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Message</span>
                            <span>021 123 145 14</span>
                        </div>
                    </div>
                    <div className="flexCenter button">Message Now</div>
                </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
        <div className="c-right">
          <div className="image-container">
            <img src="../img/contact.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
