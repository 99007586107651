import "./GetStarted.css";

const GetStarted = () => {
  return (
    <section className="g-wrapper">
      <div className="paddings innerWidth g-container">
        <div className="flexColCenter inner-container">
          <span className="primaryText">Get Stated With Homyz</span>
          <span className="secondaryText">
            Subscribe and find Super attractive price quotes from Homyz
            <br /> Find Your residence soon
          </span>
            <button className="button">
                <a href="mailto:zaydssh500@gmail.com">Get Started</a>
            </button>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
